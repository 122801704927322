import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navigation.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../imgs/logo.png';
function Navigation() {
  return (
<Navbar sticky="top" collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        <Navbar.Brand href="/"><img src={logo} alt=""/> Parafia Piotrowice</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Aktualności" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/ogloszenia">Ogłoszenia</NavDropdown.Item>
              <NavDropdown.Item href="/ofiarodawcy">Ofiarodawcy</NavDropdown.Item>
              <NavDropdown.Item href="/zapowiedzi">Zapowiedzi</NavDropdown.Item>
              <NavDropdown.Item href="/intencje">Intencje</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Grupy Parafialne" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/rozerozancowe">Roże Różańcowe</NavDropdown.Item>
              <NavDropdown.Item href="/ministranci">Ministrańci</NavDropdown.Item>
              <NavDropdown.Item href="/radaparafialna">Rada Parafialna</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Łowiczki" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/intecjelowiczki">Intecje</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/sakramenty">Sakramenty i posługi</Nav.Link>
            <Nav.Link href="/galeria">Galeria</Nav.Link>
            <Nav.Link href="/kontakt">Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
