import '../App.css';
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer';
import { globalVar } from '../globalVar';
function Ogloszenia() {
  return (<>
        <Navigation />
        <div className='article'>
            <div className='title'>Ogłoszenia {globalVar.map((map,index) => {return map.niedziela})} </div>
            <p>Kalendarz Liturgiczny</p>
            <ul>
                <li>poniedziałek- wspomnienie św.Marcina z Tours, biskupa</li>
                <li>wtorek - wspomnienie św.Jozafata Kuncewicza, biskupa męczennika</li>
                <li>środa - wspomnienie św.św.Benedykta, Jana, Mateusza, Izaaka, Krystyna, pierwszych męczenników Polski</li>
            </ul>
            <p>
                Jutro, 11-go listopada, przypada 106 rocznica odzyskania Niepodległości. O
                godz.10.00 na Mszy św. będziemy się modlić o błogosławieństwo dla naszej Ojczyzny, o
                ducha rozmiłowania Jej przez wszystkich Naród Polski stanowiących, o dobre rządy i
                poszanowanie praw wszystkich obywateli i o dar nieba dla tych wszystkich, którzy złożyli
                swe życie dla Jej wolności i chwały. Znajdźmy czas i przeżyjmy ten dzień również w
                rozmodleniu, zanosząc przed ołtarz nasze prośby w intencji ziemskiej Ojczyzny.
                Zapraszam poczty sztandarowe: OSP, Górników, Szkoły – i wszystkich, którym na sercu
                leży dobro Polski.
            </p>
            <p>
                W środę o godz.18.00 Msza św. i Nowenna do MBNP, przed Mszą św. różaniec w
                intencji zmarłych wypominanych w wypominkach rocznych, prowadzony przez VI
                Różę.
            </p>
            <p>
                W piątek na Mszy św. o godz. 18.00, kolejne spotkanie kandydatów z klas VIII-ych,
                w ramach nowenny przed przyjęciem sakramentu Bierzmowania. Zapraszam również
                rodziców, chrzestnych i bliskich kandydatów.
            </p>
            <p>
                W sobotę 16-go listopada pielgrzymujemy na Podhale do Ludźmierza –
                Sanktuarium Gaździny Podhala i Dębna. Wyjazd o godz. 7.45 z parkingu przy kościele,
                powrót ok. godz. 17.00.
            </p>
            <p>
                W niedzielę 8 grudnia na Mszy św. o godz.11.00 górnicy czcić będą swoją patronkę
                św.Barbarę, a po uroczystości w kościele, bedą mieli spotkanie przy stole w Remizie OSP.
                Bardzo proszę górników emerytów jak i pracujących, aby potwierdzili swój udział w
                spotkaniu u: Jerzego Kuski <a href="tel:+48512104857">512-104-857</a> lub Łukasza Zięby 
                <a href="tel:+48664439351">664-439-351</a>, do 30-go listopada.
            </p>
            <p>
                Dzierżawców gruntów parafialnych, proszę, aby do końca listopada złożyli
                deklaracje o przedłużeniu umów dzierżawy, bądź rezygnacji; nowe umowy dzierżaw na
                kolejne 2 lata podpiszemy 2 stycznia.
            </p>
            <p>
                Dziekuję za złożone dziś ofiary na ogrzewanie i energię elektryczną kościoła i
                kaplicy na Łowiczkach. Bóg zapłać!
            </p>
            <p>Dziękuję za sprzątanie świątynii na dzisiejszą niedzielę i ofiarę 200zł, na przyszłą niedzielę troskę o świątynię powierzamy rodzinom:</p>
            <ul>
                <li>Marka i Krystyny Czekan</li>
                <li>Daniela i Natalii Czekan</li>
                <li>Piotra i Barbary Franica</li>
                <li>Józefa i Wioletty Brusik</li>
                <li>Józefa i Danuty Nowak</li>
            </ul>
            <p>
                Dziękuję za sprzątanie kaplicy i ofiarę 100zł, w tym tygodniu troskę o kaplicę powierzamy:
            </p>
            <ul>
                <li>Monice Węgrzyn</li>
                <li>Marcie Sęk</li>
                <li>Agacie Jezierskiej</li>
                <li>Kindze Cieślar</li>
            </ul>
            <p>
                Z naszej Wspólnoty do wieczności Pan Bóg powołał:
            </p>
            <ul>
                <li>śp.Helenę Toporek z ul.Kościelnej</li>
            </ul>
            <p>
                Dobry, Jezu, a nasz Panie, daj jej wieczne spoczywanie.
            </p>
        </div>
        <Footer />
  </>
  );
}

export default Ogloszenia;
