import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function Zapowiedzi() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Zapowiedzi Przedślubne 9/2024</div>
            </div>
            <Footer />
        </>
    )
}

export default Zapowiedzi