import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function IntencjeLowiczki() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Intecje Łowiczki</div>
                <ul>
                    <li>Niedziela 3.11 + Wiesław Dąbrowski (od syna Tomasza z rodz.)</li>
                    <li>Niedziela 10.11 + Mieczysław Jurkowski rocz.</li>
                    <li>Niedziela 17.11 + Stanisław Ryszka</li>
                    <li>Niedziela 24.11 ++ Janina Krzyścin, rodzice</li>
                    <li>Niedziela 1.12</li>
                    <li>1. ++ Stanisław Józefa Balcer</li>
                    <li>2. + Józef Wolanin (od syna Jacka z żoną)</li>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default IntencjeLowiczki