import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'
import p1 from '../imgs/kancelaria.jpg'

function Kontakt() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <img className='centerimg' src={p1} alt="o1" />
            </div>
            <Footer />
        </>
    )
}

export default Kontakt