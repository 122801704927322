import '../App.tsx'

function Poczta() {
    window.location.replace('https://accounts.zoho.eu/signin?servicename=VirtualOffice&signupurl=https://www.zoho.eu/mail/zohomail-pricing.html&serviceurl=https://mail.zoho.eu');
    return(
        <>
        
        </>
    )
}

export default Poczta