import '../App.tsx'
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'

function RadaParafialna() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>RADA PARAFIALNA PARAFII NAWIEDZNIA NAJŚWIĘTSZEJ MARYJI PANNY W PIOTROWICACH</div>
        <ul>
            <li>1. ks.Tomasz Rajda</li>
            <li>2. Władysław Poręba (z urzędu)</li>
            <li>3. Jan Gliwa (z urzędu)</li>
            <li>4. Marcin Jurecki (z urzędu)</li>
            <li>5. Łukasz Zimnal (z wyboru)</li>
            <li>6. Marcin Gagracz (z wyboru)</li>
            <li>7. Maciej Wędrzyk (z wyboru)</li>
            <li>8. Jerzy Kuska (z wyboru)</li>
            <li>9. Kazimierz Stachura (z wyboru)</li>
            <li>10. Gabriel Całus (z wyboru)</li>
            <li>11. Andrzej Wojtaszek (z wyboru)</li>
            <li>12. Piotr Franica (z wyboru)</li>
            <li>13. Jan Szymonik (z wyboru)</li>
            <li>14. Sebastian Makuch (z nominacji)</li>
            <li>15. Krystian Poręba (z nominacji)</li>
            <li>16. Jan Stolarczyk (z nominacji)</li>
        </ul>
            </div>
            <Footer />
        </>
    )
}

export default RadaParafialna