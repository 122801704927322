import '../App.css'
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'
import './repeat.css'

function Ofiarodawcy(){
    return (
        <>
            <Navigation />
            <div className='article'>
            <div className='title'>OFIARA NA POTRZEBY KOŚCIOŁA PAŹDZIERNIK 2024</div>
            <table>
                <tr><td>1</td><td>Brzeźniak</td><td>Łowiczki Graniczna 38</td><td>100zł</td></tr>
                <tr><td>2</td><td>Dorota Dąbrowska</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>3</td><td>Tadeusz Gancarczyk</td><td>Łowiczki Centralna 28</td><td>100zł</td></tr>
                <tr><td>4</td><td>Jan Gliwa</td><td>Łowiczki Centralna 8</td><td>200zł</td></tr>
                <tr><td>5</td><td>A.B.Głąb</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>6</td><td>A.S.Głąb</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>7</td><td>J.J.Głąb</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>8</td><td>Maria Głąb</td><td>Łowiczki Centralna 80</td><td>100zł</td></tr>
                <tr><td>9</td><td>B.B.Jezierscy</td><td>Łowiczki Centralna 194</td><td>50zł</td></tr>
                <tr><td>10</td><td>M.M.Jezierscy</td><td>Łowiczki Centralna 141</td><td>100zł</td></tr>
                <tr><td>11</td><td>Anna Kciuk</td><td>Łowiczki Centralna 6</td><td>50zł</td></tr>
                <tr><td>12</td><td>A.Kozaczka</td><td>Łowiczki Kanada 22</td><td>50zł</td></tr>
                <tr><td>13</td><td>Jan Urszula Kreczmer</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>14</td><td>J.A.Majkut</td><td>Łowiczki Centralna 85</td><td>100zł</td></tr>
                <tr><td>15</td><td>I.Miętka</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>16</td><td>A.P.Oleś</td><td>Łowiczki Centralna 105</td><td>100zł</td></tr>
                <tr><td>17</td><td>Karolina Michał Opyrchał</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>18</td><td>Pardygał</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>19</td><td>Marzena Józef Seremet</td><td>Łowiczki</td><td>400zł</td></tr>
                <tr><td>20</td><td>H.K.Stachura</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>21</td><td>Lucyna Stanisław Stachura</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>22</td><td>Stanisława Stachura</td><td>Łowiczki Centralna</td><td>200zł</td></tr>
                <tr><td>23</td><td>B.W.</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>24</td><td>B.A.Wojtaszek</td><td>Łowiczki</td><td>100zł</td></tr>
                <tr><td>25</td><td>Bronisława Antecka</td><td>Andrychowska</td><td>100zł</td></tr>
                <tr><td>26</td><td>J.A.Anteccy</td><td>Kasztanowa 109</td><td>100zł</td></tr>
                <tr><td>27</td><td>M.L.Anteccy</td><td>Andrychowska 13</td><td>100zł</td></tr>
                <tr><td>28</td><td>Augustyn</td><td>Modrzewiowa</td><td>50zł</td></tr>
                <tr><td>29</td><td>Bałys</td><td>Andrychowska 346</td><td>100zł</td></tr>
                <tr><td>30</td><td>Bernaś, Momot</td><td>Akacjowa 31</td><td>100zł</td></tr>
                <tr><td>31</td><td>Stanisław Maria Boba</td><td>Andrychowska 352</td><td>200zł</td></tr>
                <tr><td>32</td><td>Lucyna Borowska</td><td>Andrychowska 307</td><td>100zł</td></tr>
                <tr><td>33</td><td>Rafał Bratek</td><td>Akacjowa 89</td><td>100zł</td></tr>
                <tr><td>34</td><td>D.Brzeska, K.Fudela</td><td>Bukowiecka</td><td>200zł</td></tr>
                <tr><td>35</td><td>W.Brusik</td><td>Andrychowska 280</td><td>200zł</td></tr>
                <tr><td>36</td><td>M.G.Całus</td><td></td><td>200zł</td></tr>
                <tr><td>37</td><td>L.S.Czopek</td><td>Andrychowska 262</td><td>100zł</td></tr>
                <tr><td>38</td><td>M.J.Dedko</td><td>Andrychowska 3</td><td>90zł</td></tr>
                <tr><td>39</td><td>K.J.Domider</td><td>Andrychowska 216</td><td>100zł</td></tr>
                <tr><td>40</td><td>Dziedzic</td><td>Lipowa</td><td>100zł</td></tr>
                <tr><td>41</td><td>Frączek, Sanak</td><td>Andrychowska 161</td><td>100zł</td></tr>
                <tr><td>42</td><td>Anna Frączek</td><td>Wrzosowa 22</td><td>100zł</td></tr>
                <tr><td>43</td><td>B.Ł.Frączek</td><td></td><td>100zł</td></tr>
                <tr><td>44</td><td>I.S.Frączek</td><td>Andrychowska 163</td><td>200zł</td></tr>
                <tr><td>45</td><td>J.R.Frączek</td><td>Akacjowa 6</td><td>100zł</td></tr>
                <tr><td>46</td><td>Stanisław Czesława Frączek</td><td>Kasztanowa 134</td><td>100zł</td></tr>
                <tr><td>47</td><td>T.J.Frączek</td><td></td><td>100zł</td></tr>
                <tr><td>48</td><td>T.K.Frączek</td><td>Andrychowska 297</td><td>100zł</td></tr>
                <tr><td>49</td><td>U.P.Frączek</td><td>Akacjowa 111</td><td>100zł</td></tr>
                <tr><td>50</td><td>Jacek Marta gagracz</td><td>Kasztanowa 132</td><td>200zł</td></tr>
                <tr><td>51</td><td>Krystyna Gagracz</td><td>Kasztanowa 130</td><td>50zł</td></tr>
                <tr><td>52</td><td>Marcin Gagracz</td><td>Akacjowa 96</td><td>100zł</td></tr>
                <tr><td>53</td><td>Józef Głąb</td><td>Modrzewiowa</td><td>200zł</td></tr>
                <tr><td>54</td><td>J.N.Głąb</td><td>Modrzewiowa 45</td><td>50zł</td></tr>
                <tr><td>55</td><td>W.M.Gorzkowscy</td><td></td><td>100zł</td></tr>
                <tr><td>56</td><td>Górecki, Kobiałka</td><td></td><td>300zł</td></tr>
                <tr><td>57</td><td>A.T.Góreccy</td><td>Lipowa 14</td><td>100zł</td></tr>
                <tr><td>58</td><td>Alojzy Górecki</td><td>Andrychowska 69</td><td>200zł</td></tr>
                <tr><td>59</td><td>Elżbieta Franciszek Góreccy</td><td>Akacjowa 44</td><td>100zł</td></tr>
                <tr><td>60</td><td>Jan Górecki</td><td>Kasztanowa 142</td><td>200zł</td></tr>
                <tr><td>61</td><td>Hałatek</td><td>Lipowa</td><td>100zł</td></tr>
                <tr><td>62</td><td>R.L.Janik</td><td>Pagórkowa 19</td><td>100zł</td></tr>
                <tr><td>63</td><td>Amalia Jarosz</td><td>Lipowa 77</td><td>100zł</td></tr>
                <tr><td>64</td><td>Agata Jurecka</td><td>Andrychowska 196</td><td>100zł</td></tr>
                <tr><td>65</td><td>Piotr Marcin Jureccy</td><td>Andrychowska 2</td><td>100zł</td></tr>
                <tr><td>66</td><td>M.P.Kaleńscy</td><td>Andrychowska 280</td><td>400zł</td></tr>
                <tr><td>67</td><td>Kobiałka</td><td>Bukowiecka 42</td><td>100zł</td></tr>
                <tr><td>68</td><td>Renata Kobylańska</td><td></td><td>50zł</td></tr>
                <tr><td>69</td><td>Karolina Rafał Kolasa</td><td>Wrzosowa 40</td><td>100zł</td></tr>
                <tr><td>70</td><td>S.A.Kolasa</td><td>Andrychowska 173</td><td>100zł</td></tr>
                <tr><td>71</td><td>Stanisława Kowalczyk</td><td>Modrzewiowa 14</td><td>200zł</td></tr>
                <tr><td>72</td><td>Kozak</td><td>Kasztanowa 5</td><td>100zł</td></tr>
                <tr><td>73</td><td>E.P.Kozak</td><td>Andrychowska 233</td><td>50zł</td></tr>
                <tr><td>74</td><td>T.S.Kozak</td><td>Andrychowska 234</td><td>100zł</td></tr>
                <tr><td>75</td><td>Rozalia Janusz Kozłowscy</td><td>Wrzosowa 24</td><td>200zł</td></tr>
                <tr><td>76</td><td>A.K.Krawczyk</td><td>Akacjowa</td><td>100zł</td></tr>
                <tr><td>77</td><td>M.K.Krzaczyńscy</td><td>Kasztanowa 95</td><td>50zł</td></tr>
                <tr><td>78</td><td>D.A.Krzyścin</td><td>Pagórkowa 19</td><td>100zł</td></tr>
                <tr><td>79</td><td>Kubas</td><td>Andrychowska 380</td><td>50zł</td></tr>
                <tr><td>80</td><td>Władysława Jolanta Kubas</td><td>Akacjowa 21</td><td>100zł</td></tr>
                <tr><td>81</td><td>T.J.Kusak</td><td>Andrychowska 238</td><td>50zł</td></tr>
                <tr><td>82</td><td>Dorota Kuska</td><td>Akacjowa 39</td><td>200zł</td></tr>
                <tr><td>83</td><td>Jerzy Kuska</td><td>Lipowa 73</td><td>100zł</td></tr>
                <tr><td>84</td><td>Leszek Kuska</td><td>Kasztanowa 2</td><td>100zł</td></tr>
                <tr><td>85</td><td>Leszek Stanisława Kuska</td><td>Akacjowa 23</td><td>150zł</td></tr>
                <tr><td>86</td><td>Stamisław Kuska</td><td>Kasztanowa 4</td><td>50zł</td></tr>
                <tr><td>87</td><td>Kuśpik, Kalman</td><td>Andrychowska 176</td><td>200zł</td></tr>
                <tr><td>88</td><td>B.A.Kuwik</td><td>Lipowa 57</td><td>100zł</td></tr>
                <tr><td>89</td><td>Łubiarz</td><td>Lipowa 65</td><td>100zł</td></tr>
                <tr><td>90</td><td>Makuch</td><td>Andrychowska 134</td><td>100zł</td></tr>
                <tr><td>91</td><td>Makuch</td><td>Andrychowska 288</td><td>100zł</td></tr>
                <tr><td>92</td><td>Józef Makuch</td><td>Akacjowa</td><td>100zł</td></tr>
                <tr><td>93</td><td>Józefa Makuch</td><td>Lipowa</td><td>100zł</td></tr>
                <tr><td>94</td><td>Sebastian Makuch</td><td></td><td>300zł</td></tr>
                <tr><td>95</td><td>Waldemar Makuch</td><td>Akacjowa</td><td>100zł</td></tr>
                <tr><td>96</td><td>Wanda Makuch, Piotr Bogusława Krawczyk</td><td>Andrychowska 408</td><td>500zł</td></tr>
                <tr><td>97</td><td>Halina Malik</td><td>Andrychowska 120</td><td>100zł</td></tr>
                <tr><td>98</td><td>Antoni Mamoń</td><td>Andrychowska 131</td><td>50zł</td></tr>
                <tr><td>99</td><td>Zofia Matla</td><td>Akacjowa 95</td><td>100zł</td></tr>
                <tr><td>100</td><td>Michalska</td><td>Andrychowska</td><td>100zł</td></tr>
                <tr><td>101</td><td>K.J.Miłoń</td><td>Andrychowska 150</td><td>200zł</td></tr>
                <tr><td>102</td><td>Stanisław Młynarczyk</td><td>Akacjowa 56</td><td>100zł</td></tr>
                <tr><td>103</td><td>Danuta Nikliborc</td><td>Kasztanowa 121</td><td>100zł</td></tr>
                <tr><td>104</td><td>K.E.Nocula</td><td>Andrychowska 346</td><td>100zł</td></tr>
                <tr><td>105</td><td>Irena Nowak</td><td></td><td>100zł</td></tr>
                <tr><td>106</td><td>Józef Nowak</td><td>Kasztanowa 122</td><td>100zł</td></tr>
                <tr><td>107</td><td>Marianna Nowak</td><td>Modrzewiowa 34</td><td>50zł</td></tr>
                <tr><td>108</td><td>Wiktoria Noworyta</td><td></td><td>50zł</td></tr>
                <tr><td>109</td><td>E.Nykiel</td><td>Lipowa 72</td><td>100zł</td></tr>
                <tr><td>110</td><td>M.Nykiel</td><td></td><td>50zł</td></tr>
                <tr><td>111</td><td>Stanisław Ochmański</td><td>Akacjowa 55</td><td>200zł</td></tr>
                <tr><td>112</td><td>Krzysztof Oczkoś</td><td>Akacjowa 11</td><td>100zł</td></tr>
                <tr><td>113</td><td>Ortman</td><td>Jaśminowa 20</td><td>500zł</td></tr>
                <tr><td>114</td><td>Pacyga</td><td></td><td>100zł</td></tr>
                <tr><td>115</td><td>D.K.Pieczonka</td><td>Andrychowska 171</td><td>100zł</td></tr>
                <tr><td>116</td><td>Anna Pietras</td><td>Kościelna 14</td><td>50zł</td></tr>
                <tr><td>117</td><td>A.W.Płonka</td><td>Kościelna 6</td><td>100zł</td></tr>
                <tr><td>118</td><td>S.W.Poręba</td><td></td><td>100zł</td></tr>
                <tr><td>119</td><td>Krystian Urszula Poręba</td><td></td><td>50zł</td></tr>
                <tr><td>120</td><td>B.R</td><td>Wrzosowa 17</td><td>50zł</td></tr>
                <tr><td>121</td><td>E.W.Rał</td><td>Andrychowska</td><td>100zł</td></tr>
                <tr><td>122</td><td>Leszek Maria Rogalscy</td><td>Andrychowska 210</td><td>200zł</td></tr>
                <tr><td>123</td><td>Rozalia Rola</td><td>Andrychowska 225</td><td>100zł</td></tr>
                <tr><td>124</td><td>E.M.Skawina</td><td>Modrzewiowa 10</td><td>100zł</td></tr>
                <tr><td>125</td><td>Agata Sporysz</td><td>Andrychowska 85</td><td>100zł</td></tr>
                <tr><td>126</td><td>Ewa Sporysz</td><td>Andrychowska 85</td><td>50zł</td></tr>
                <tr><td>127</td><td>Bogusław Staszczak</td><td>Andrychowska 270</td><td>700zł</td></tr>
                <tr><td>128</td><td>Szatan</td><td>Andrychowska 272</td><td>100zł</td></tr>
                <tr><td>129</td><td>B.A.Szymańscy</td><td>Andrychowska 410</td><td>50zł</td></tr>
                <tr><td>130</td><td>A.A.Środa</td><td>Akacjowa 77</td><td>100zł</td></tr>
                <tr><td>131</td><td>Kazimierz Stanisława Ubik</td><td>Wrzosowa 2</td><td>100zł</td></tr>
                <tr><td>132</td><td>W.D.Ubik</td><td>Wrzosowa 4</td><td>100zł</td></tr>
                <tr><td>133</td><td>T.J.Ulczok</td><td>Kasztanowa 32</td><td>100zł</td></tr>
                <tr><td>134</td><td>M.W.Wasztyl</td><td></td><td>100zł</td></tr>
                <tr><td>135</td><td>Ważydrąg</td><td>Źródlana</td><td>100zł</td></tr>
                <tr><td>136</td><td>Stanisława Wędrzyk</td><td>Kasztanowa</td><td>100zł</td></tr>
                <tr><td>137</td><td>Łukasz Wiśniecki</td><td>Lipowa 43</td><td>100zł</td></tr>
                <tr><td>138</td><td>A.Włoszek</td><td></td><td>50zł</td></tr>
                <tr><td>139</td><td>Wnętrzak</td><td></td><td>200zł</td></tr>
                <tr><td>140</td><td>Wojtala</td><td>Andrychowska 172</td><td>50zł</td></tr>
                <tr><td>141</td><td>Zaczyk</td><td>Lipowa</td><td>100zł</td></tr>
                <tr><td>142</td><td>A.C.Zając</td><td>Andrychowska 111</td><td>100zł</td></tr>
                <tr><td>143</td><td>J.K.Zając</td><td>Andrychowska 101</td><td>100zł</td></tr>
                <tr><td>144</td><td>Jan Rozalia Zając</td><td>Andrychowska 376</td><td>100zł</td></tr>
                <tr><td>145</td><td>Krzysztof Zając</td><td>Pagórkowa</td><td>200zł</td></tr>
                <tr><td>146</td><td>W.Zarzycki</td><td>Andrychowska 312</td><td>100zł</td></tr>
                <tr><td>147</td><td>Zdżalik</td><td>Wrzosowa 25</td><td>50zł</td></tr>
                <tr><td>148</td><td>T.J.Zielińscy</td><td>Andrychowska 29</td><td>50zł</td></tr>
                <tr><td>149</td><td>A.Ł.Zięba</td><td>Andrychowska 351</td><td>200zł</td></tr>
                <tr><td>150</td><td>Stanisława Zięba</td><td>Andrychowska 40</td><td>200zł</td></tr>
                <tr><td>151</td><td>Józef Zimnal</td><td>Kasztanowa 111</td><td>100zł</td></tr>
                <tr><td>152</td><td>Ł.Ł.Zimnal</td><td>Kasztanowa</td><td>100zł</td></tr>
                <tr><td>153</td><td>J.Żuraw</td><td></td><td>50zł</td></tr>
                <tr><td>154</td><td>Witold Żuraw</td><td>Andrychowska 355</td><td>100zł</td></tr>
                <tr><td>155</td><td>Jacek Żuraw</td><td>Andrychowska 248</td><td>200zł</td></tr>
                <tr><td>156</td><td>Bożena Żuraw</td><td>Andrychowska 348</td><td>100zł</td></tr>
                <tr><td>157</td><td>Beata Żuraw</td><td>Kasztanowa 9</td><td>50zł</td></tr>
                <tr><td>158</td><td>T.W.Żuraw</td><td>Andrychowska 167</td><td>100zł</td></tr>
                <tr><td>159</td><td>Łukasz Żuraw</td><td>Wrzosowa 21</td><td>50zł</td></tr>
            </table>
            </div>
            <Footer />
        </>
)}

export default Ofiarodawcy