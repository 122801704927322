import Navigation from '../componets/Navigation';
import Footer from '../componets/Footer';
import Carousel from 'react-bootstrap/Carousel';
import Slide1 from '../imgs/slide1.JPEG';
import Slide2 from '../imgs/slide2.JPEG';
import Slide3 from '../imgs/slide3.jpg';
import Slide4 from '../imgs/slide4.JPEG';
import Slide5 from '../imgs/slide5.JPEG';
import Slide6 from '../imgs/slide6.JPEG';
import Slide7 from '../imgs/slide7.JPEG';

function Galeria() {
    return(
        <>
            <Navigation />
            <div className='article'>
                <div className='title'>Galeria</div>
                <div className='slideshow'>
                    <Carousel>
                        <Carousel.Item>
                            <img className="imgs" src={Slide1} alt="obraz"/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="imgs" src={Slide2} alt="obraz"/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="imgs" src={Slide3} alt="obraz"/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="imgs" src={Slide4} alt="obraz"/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="imgs" src={Slide5} alt="obraz"/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="imgs" src={Slide6} alt="obraz"/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="imgs" src={Slide7} alt="obraz"/>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Galeria